@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&family=Work+Sans&display=swap');

:root{
  --pop__color-primary: #226bbe;
  --pop__color-primary-hover: #0f427c;
  --pop__color-btn-footer-hover: #0f427c;
  --pop__color-primary-active: #0f427c;
  --pop__active-font-color: #FFFFFF;
  --pop__font-button-color: white;
  --pop__font-family: font-family: 'Noto Sans', sans-serif;
  --pop__border-color-primary: #226bbe2c;
  --pop__border-color-base: #10182836;
  --pop__background: #FFFFFF;
  --pop__background-textarea: #FFFFFF;
  --pop__bg-button-confirm: #226bbe;
  --pop__font-button-confirm-color: #FFFFFF;
  --font-text-color: black;
  --display-emoji: none;
}

* {
  font-family: var(--pop__font-family);
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  /* background-color: white; */
}

.pop__container {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 99999999;
}
.pop__content {
  width: 30rem;
  background-color: var(--pop__background);
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  height: 0;
  transition: height 1s ease;
  box-shadow: 0 1px 24px var(--pop__border-color-base);
  overflow: hidden;
}
.pop__content.pop__background {
  height: 6.1rem;
  background: var(--pop__background);
}

.pop__title {
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: bold;
}
.pop__close{
  background: none;
  border: none;
  cursor: pointer;
}
.pop__header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}
.pop__body{
  padding: 0rem 1rem;
}
.pop__evaluation {
  padding-bottom: 1rem;
}
.pop__evaluation > .pop__buttons > button {
  background-color: var(--pop__color-primary);
  color: var(--pop__font-button-color);
  border-radius: 0.8rem;
  /* padding-inline: .9rem; */
  width: 38px;
  box-shadow: none;
  border: none;
  cursor: pointer;
  transition: 0.2s;
}

.pop__evaluation > .pop__buttons > button:hover {
  background-color: var(--pop__color-primary-active);
  color: var(--pop__active-font-color);
}

.pop__footer > button:hover {
  background-color: var(--pop__color-btn-footer-hover);
}

.pop__buttons button.pop__active {
  background-color: var(--pop__color-primary-active);
  color: var(--pop__active-font-color);
}
#evaluation_text{
  width: 100%;
  border-radius: 0.8rem;
  background: var(--pop__background-textarea);
  box-sizing: border-box;
  padding: 10px;
  resize: none;
  border: 1px solid;
  transition: 0.2s;
}

#evaluation_text:focus-visible {
  outline-offset: 0px;
  border: 1px solid var(--pop__color-primary);
  outline: none;
  box-shadow: 0 1px 24px var(--pop__border-color-primary);
}
.pop__hint {
  margin: 0;
  font-size: 14px;
}

.pop__emoji {
  font-size: 25px;
  display: var(--display-emoji);
}

.pop__mkt_action,
.pop__checkbox,
.pop__evaluation > .pop__buttons,
.pop__hint_evaluation {
  display: flex;
  justify-content: space-between;
}

.pop__footer > button {
  box-sizing: border-box;
  width: 93.5%;
  margin: 1rem;
  padding-bottom: 1rem;
  padding: 0.5rem;
  border: none;
  background: var(--pop__bg-button-confirm);
  color: var(--pop__font-button-confirm-color);
  cursor: pointer;
  border-radius: 0.5rem;
}

.disabled__button {
  background-color: lightgray !important;
  cursor: not-allowed;
}

.pop__close,
.pop__title,
.pop__hint {
  color: var(--font-text-color);
  font-family: var(--pop__font-family);
}

.pop__button_question {
  height: 37px;
}